<template>
  <div class="relative flex justify-end rounded-full">
    <div
      :class="
        value > 0
          ? 'translate-x-0 opacity-100 bigWhiteDropShadow'
          : 'translate-x-4 opacity-0 pointer-events-none'
      "
      class="flex w-18 transition-all duration-70 ease-linear absolute right-0 bg-gray-tint rounded-full"
    >
      <button
        class="rounded-full w-11 h-11 flex items-center border-none bg-gray-tint justify-center text-onyx-500"
        :disabled="value <= 0"
        @click="decrease"
      >
        <SvgIcon icon="minus" class="w-4 h-4" />
      </button>
      <div
        class="flex items-center w-4 text-center justify-center pointer-events-none select-none"
      >
        {{ value }}
      </div>
    </div>
    <button
      class="rounded-full w-11 h-11 flex items-center border-none relative z-40 bg-gray-tint justify-center text-onyx-500 disabled:text-gray-text/30 transition-colors duration-150 focus:outline-none focus:shadow-focus active:shadow-transparent"
      :disabled="disableIncrease"
      @click="increase"
    >
      <SvgIcon icon="plus" class="w-4 h-4" />
    </button>
  </div>
</template>

<script setup lang="ts">
  defineProps<{ value: number; disableIncrease: boolean }>()

  const emit = defineEmits<{
    onIncrease: [event: MouseEvent]
    onDecrease: [event: MouseEvent]
  }>()

  const increase = (e: MouseEvent) => {
    emit("onIncrease", e)
  }
  const decrease = (e: MouseEvent) => {
    emit("onDecrease", e)
  }
</script>

<style scoped>
  .bigWhiteDropShadow {
    box-shadow: -5px 0 5px 5px rgba(255, 255, 255, 0.905);
  }
</style>
